.modal {
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue, helvetica, ubuntu, roboto, noto, segoe ui, arial, sans-serif;
}

.modal__overlay {
  background: #0009;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  inset: 0;
}

.modal__container {
  max-width: 1000px;
  max-height: 700px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 4px;
  padding: 30px;
  overflow-y: auto;
}

.wrap__modal {
  flex-direction: row;
  justify-content: center;
  display: flex;
}

.modal__header {
  justify-content: space-between;
  display: flex;
}

.modal__title {
  color: #00449e;
  box-sizing: border-box;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.25;
}

.modal__close {
  background: none;
  border: 0;
}

.modal__header .modal__close:before {
  content: "✕";
}

.header_footer__modal {
  padding-left: 30px;
  padding-right: 15px;
}

.modal__content {
  color: #000c;
  margin-top: 2rem;
  margin-bottom: 2rem;
  line-height: 1.5;
}

.modal__btn {
  color: #000c;
  cursor: pointer;
  text-transform: none;
  will-change: transform;
  -moz-osx-font-smoothing: grayscale;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-color: #e6e6e6;
  border-style: none;
  border-width: 0;
  border-radius: .25rem;
  margin: 0;
  padding: .5rem 1rem;
  font-size: .875rem;
  line-height: 1.15;
  transition: transform .25s ease-out, -webkit-transform .25s ease-out;
  overflow: visible;
  transform: translateZ(0);
}

.modal__btn:focus, .modal__btn:hover {
  transform: scale(1.05);
}

.modal__btn-primary {
  color: #fff;
  background-color: #f8ffa6;
}

@keyframes mmfadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes mmfadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes mmslideIn {
  from {
    transform: translateY(15%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes mmslideOut {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-10%);
  }
}

.micromodal-slide {
  display: none;
}

.micromodal-slide.is-open {
  display: block;
}

.micromodal-slide[aria-hidden="false"] .modal__overlay {
  animation: .3s cubic-bezier(0, 0, .2, 1) mmfadeIn;
}

.micromodal-slide[aria-hidden="false"] .modal__container {
  animation: .3s cubic-bezier(0, 0, .2, 1) mmslideIn;
}

.micromodal-slide[aria-hidden="true"] .modal__overlay {
  animation: .3s cubic-bezier(0, 0, .2, 1) mmfadeOut;
}

.micromodal-slide[aria-hidden="true"] .modal__container {
  animation: .3s cubic-bezier(0, 0, .2, 1) mmslideOut;
}

.micromodal-slide .modal__container, .micromodal-slide .modal__overlay {
  will-change: transform;
}

/*# sourceMappingURL=index.9d550982.css.map */
